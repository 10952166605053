.cart {
  margin-top: 0;
  /* margin-bottom: 530;
  background-color: blue; */
}

img {
  object-fit:cover;
  z-index:1;
  width:100%;
  height: 100%;
  z-index: inherit;
}

p {
  padding: 2;
  color:black;
  margin:2;
}

.small {
  color:black;
  font-size: 12px;
}


h1 {
  padding: 5;
  margin:5;
  color:black;

}

.small{
  margin-top: -5px;
  padding: 0;
  margin:0;
}
button {
  /* background-color: antiquewhite; */
  margin-left: 20px;
  margin-right: 20px;
}