.cart {
  margin-top: 0;
  /* margin-bottom: 530;
  background-color: blue; */
}

img {
  object-fit:cover;
  z-index:1;
  width:100%;
  height: 100%;
  z-index: inherit;
}

.p_style {
margin: 0px;
padding:0px;
}
