/* Add this CSS to your styles */
.search-button {
    background-color: #4caf50;
    /* border: none; */
    /* color: white; */
    /* padding: 15px 32px; */
    /* text-align: center;
    text-decoration: none;
    display: inline-block; */
    /* font-size: 16px; */
    /* margin: 4px 2px; */
    /* cursor: pointer; */
    /* border-radius: 4px; */
    transition: transform 0.2s ease-in-out;
  }
  
  .search-button:hover {
    background-color: #45a049;
  }
  
  /* Animation for the button */
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-10px);
    }
  }
  
  .search-button:active {
    animation: bounce 0.5s ease infinite; /* 'infinite' makes the animation loop */
  }