@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Ruda:wght@400;600;700&display=swap");


header {
	background: #ffffff;
  padding: 0.5rem 0;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0rem 1.5rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.logo img {
  width: 30%;
  object-fit: cover;
  margin-right: 8px;
}

.logo span {
  font-size: 2rem;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li {
  margin: 0rem 0.4rem;
  align-items: center;
  padding: 0.5rem 0.5rem;
}

nav ul li a {
  text-decoration: none;
  font-size: 1.1rem;
  letter-spacing: 2.5px;
  color: #fff;
  padding: 0.7rem 1rem;
}