.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.tangazoGradient {
  background-color: #170022;
  background-image: linear-gradient(160deg, #12001b 0%, #8c0096 100%);
}
.widthAll {
  width: 30%;
  height: auto;
}

.whiteColorBg {
  background-color: white;
}

.stakeholders {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  /* background-color: #5f048b; */
  /* height: 300px; */
}

.stakeholdersEx {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  /* background-color: #5f048b; */
  /* height: 300px; */
}

.stakeholdersItem {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding-left: 5%; */
  /* background-color: #f9f9f9f9; */
  /* height: 300px; */
}

.stakeholdersItemRight {
  width: 28%;
  margin-left: 1%;
  margin-right: 1%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  background-color: white;
  /* height: 300px; */
}

.stakeholdersItemPadd {
  padding-left: 10%;
}
.stakeholdersItemPaddImage {
  padding-left: -20%;
}

.widthList {
  width: 70%;
  height: auto;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
.tangazoGradientBox {
  display: flex;
  flex-direction: row;
}
.greet-main {
  margin: 0px auto;
  margin-top: 2rem;
}

.gretting-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  /* margin-top: 80px; */
  font-size: 70px;
  line-height: 1.1;
  /* font-family: "Google Sans Bold"; */
}

.greeting-text-section-head {
  font-size: 34px;
  line-height: 1.1;
}

.greeting-text-section-head-community {
  font-size: 45px;
  text-transform: uppercase;
  line-height: 1.1;
}
.greeting-nickname {
  font-size: 30px;
  line-height: 0px;
  font-family: "Google Sans Bold Italic";
}

.greeting-text-p {
  font-size: 40px;
  line-height: 40px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-section-head {
    font-size: 30px;
    line-height: 1.1;
  }
  .greeting-text-section-head-community {
    font-size: 44px;
    margin-left: 10%;
    text-transform: uppercase;
    line-height: 1.1;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 40px;
    /* margin-top: 0px;
    text-align: center; */
  }
  .shorter {
    margin-top: -300px;
  }
  .greeting-image-div {
    visibility: hidden;
  }
  .greeting-text-section-head {
    font-size: 24px;
    line-height: 1.1;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
  }

  .portfolio-repo-btn {
    margin-right: 10px;
  }
  .tangazoGradientBox {
    display: flex;
    flex-direction: column;
  }
  .widthAll {
    width: 100%;
    height: 200px;
  }
  .widthList {
    width: 100%;
    height: auto;
  }

  .stakeholders {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* background-color: #5f048b; */
    height: auto;
  }
  .stakeholdersX {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    /* background-color: #5f048b; */
    height: auto;
  }



  .stakeholdersItem {
    /* width: 95%; */
    margin-top: 1%;
    margin-bottom: 1%;
    /* background-color:antiquewhite; */
    /* height: 300px; */
  }

  .stakeholdersItemRight {
    width: 98%;
    margin-top: 1%;
    margin-bottom: 1%;
    background-color: white;
    /* height: 300px; */
  }

  .stakeholdersItemPadd {
    padding-left: 0%;
  }
  .stakeholdersItemPaddImage {
    padding-left: 0%;
  }
}
