@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.thebody {
   margin: 0;
  padding: 0;
  box-sizing: border-box; 
  font-family: "Poppins", sans-serif;
  background-color: #575ca1;
  color: #151515;
} 

.flex-container {


  display: flex;
  width:  100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: bisque; */
  flex-direction: row;
}

.flex-item-left {
  background-color: #f1f1f1;
  /* padding:  1%; */
  width: 100%;
  flex: 100%;
}

.the-headers {
  display: none; 
}

.flex-item-right {
  background-color: #242329;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 75%;
}

.footer{
  /* background-color: cadetblue; */
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-small;
  width: 57%;
  height: 4%;
  bottom: 0;

}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
    display:flex;
    /* width: inherit; */
    position:absolute;
    width: 100%;
    height: 100%;

    top: 0;
    /* bottom: 0; */
    background-color: bisque;
  }
  .flex-item-right {

    background-color: #242329;
    width: 100%;
    /* height: 150%; */
    /* margin-top: -22%; */
    /* position: absolute; */
    top:0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-item-left {
    display: none;
    /* can just use same elemet and hide some stuffz */
  }
  .the-headers {
    position: relative;
    padding: 10px;
    width: 100%;
  }
  .footer{
   display: none;
  
  }
 
}

/* ======================== */
.login {
  font-family: "Poppins", sans-serif;
  display: grid;
  width: fit-content;
  height: fit-content;
  place-items: center;
  color: #fff;
}
.inputs_all {
  font-family: "Poppins", sans-serif;
  /* background-color: #333335; */
  display: grid;
  width: auto;
  height: fit-content;
  place-items: center;
  color: #fff;
}

.input-group {
  position: relative;
  margin-bottom: 10%;
}

.input {
  padding: 5px;
  border: none;
  margin-bottom: 10%;
  border-radius: 4px;
  font: inherit;
  color: #fff;
  background-color: transparent;
  outline: 2px solid #fff;
}

.input-label {
  position: absolute;
  font-size: small ;
  top: 0;
  left: 0;
  transform: translate(10px, 10px);
  transform-origin: left;
  transition: transform 0.25s;
}

.input:focus ~ .input-label,
.input:valid ~ .input-label {
  transform: translate(0, -30px) scale(0.8);
  color: #d1c5fc;
}

.input:is(:focus, :valid) {
  outline-color: #d1c5fc;
}

.input-title {
  place-items: left;
  position: relative;
  margin-top: 10%;
  margin-bottom: 20%;
}

.link {
  margin-top: 10%;
  color: #d1c5fc;
}