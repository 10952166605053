@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.button {
    position: relative;
    width: 50%;
    align-self: center;
    height: 25px;
    background-color: #2a2e4d;
    /* background-color: #202231; */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: width .5s,
        border-radius .5s;
}

.button * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    transition: opacity .25s;
}

.icon {
    opacity: 0;
}

.button:focus {
    width:60%;

    align-items: center;
    background-color: #44c08a;
    border-radius: 5%;
}

.button:focus .text {
    opacity: 0;
}

.button:focus .icon {
    opacity: 1;
    transition-delay: .6s;
}