.centerImgX {

    display: absolute;
    width: 100%;
    /* margin: auto; */
    /* margin-left: auto;
    margin-right: auto; */
    margin-bottom: auto;
  
    object-position: 100% 50%;
    border-radius: 10px;
  
  
  }
  