body {
  margin: 0;
}

.hero-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: calc(100vh - 100px);
  margin-bottom: 50px;
  max-height: 400px;
}

.hero-image h2 {
  font-size: 2rem;
  color: white;
  text-shadow: 3px 0px black;
  padding: 20px 20px 20px 20px;
  text-align: center;
}

.gallery {
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 10px; /* Adjust gap size as needed */
}

.gallery .img {
  width: 100%;
  height: auto;
  display: block;
}