.cart {
  margin-top: 0;
  /* margin-bottom: 530;
  background-color: blue; */
}


img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: bottom right;

}



.reserve-button,
.edit-button {
  width: 60%;
  height: 50px;
  margin-right: 5%;
  margin-left: 10%;
  border-radius: 5px;
  transition: transform 0.3s; /* Smooth transition for the shake effect */
}

.reserve-button {
  background-color: aqua;
}

.edit-button {
  height: 50px;
}

.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}
