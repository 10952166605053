
.filter-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr)); /* Adjust the column size as needed */
  gap: 5px; /* Adjust the gap between grid items */
  width: 100%; /* Adjust the width of the container */
  margin: 0 auto; /* Center the container horizontally */
  margin-top: 10px;
  padding-left: 10px;
}
.filter-grid-container div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  border-radius: 3px;
  /* border: 1px solid #ccc; */
  /* background-color: #bc98ff; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-grid-container div:hover {
  background-color: #c42eac;
  color:white;
}

.filter-grid-container div p {
  font-size: 11px; 
  font-weight: 500;

}

label {
  font-size: smaller;
  /* margin-top: -20; */
}