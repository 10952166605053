.lightbox {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow:hidden;
  background-color: rgba(0, 0, 0, 0.8);
}
.lightbox-image {
  display: block;
  margin: auto;
  max-width: 100%;
 max-height: 100%;
}
.close {
  color: #fff;
  font-size: 3em;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
}
.gallery {
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  
}
.gallery img {
  max-width: 100%;
  cursor: pointer;
}
.gallery img:hover {
  max-width: 100%;
  cursor: pointer;
}